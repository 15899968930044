import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { COLORS, FIREBASE_ERRORS, SOCIAL_AUTH, SPACING, APP_LAYOUT } from "shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { updateUserZoomAuth } from "../../redux/actions/users";

import { AuthButton, Button } from "../../shared/ui/Buttons";
import { InputForm } from "shared/ui/Inputs";
import { Link, Loader } from "shared/ui";
import logo from "assets/upmarket.png";
import media from 'shared/utils/media';

import cubicles from "assets/cubicles.png";

import styled from "styled-components";

const Seperator = styled.div`
  margin:30px 30px;
  width: 2px;
  height: 60%;
  background-color: ${COLORS.borderColor};
  ${media.lessThan('tablet')`
    height: 2px;
    width: 60%;
  `}
`;

const Cubicles = styled.img`
  z-index:0;
  position: absolute;
  height: 80%;
  // aspect-ratio:2/3;
  bottom: 0;
  right: 0;
  resize-ode: contain
`;

const Container = styled.div`
  // overflow: scroll; 
  background-size: cover;
  display: flex;
  width: 100vw;
  height: 100vh; 
  flex-direction: column; 
  align-items: center;
  justify-content: center;
`

const FormWrapper = styled.form`
  z-index: 2;
  display: flex; 
  flex-direction: row; 
  flex: 1;
  justify-content: center;
  align-items: center;
  ${media.lessThan('tablet')`
  flex-direction: column; `}
`;

const SignUpWrapper = styled.div`
  align-items: center; 
  display: flex; 
  flex-direction: column; 
  margin-top: -50px;
  ${media.lessThan('tablet')`
   margin-top: 0px; `
  }
`;

const Login = () => {
  const { login, currentUser, signInWithInviteLink, getIsSignInWithEmail, signInWithSocialLogin, signInWithCustomToken, setPageLoader } =
    useAuth();
  let navigate = useNavigate();
  const { info } = useSelector((state) => state.users);

  let apiKey = new URL(document.location).searchParams.get("apiKey");
  let invitedCompanyId = new URL(document.location).searchParams.get(
    "companyId"
  );
  let ref = new URL(document.location).searchParams.get("ref");
  let { uid, companyId } = info || {};
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmationEmail: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    try {
      await login(values.email, values.password);
      setTimeout(() => setIsLoading(false), 3000);
    } catch (error) {
      setIsLoading(false);
      let message = error;
      if (error?.code) {
        message = FIREBASE_ERRORS[error?.code];
      }
      toast(message, {
        type: "error",
        hideProgressBar: true,
      });
    }
  }

  const updateZoomAuth = async (appUserId) => {
    setIsLoading(true);
    await updateUserZoomAuth(appUserId);
    localStorage.removeItem("appUserId");
    navigate("/home");
  };

  const handleConfirmation = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await signInWithInviteLink(
        values.confirmationEmail,
        invitedCompanyId,
        ref
      );
    } catch (error) {
      setIsLoading(false);
      let message = error;
      if (error?.code) {
        message = FIREBASE_ERRORS[error.code];
      }
      toast(message, {
        type: "error",
        hideProgressBar: true,
      });
    }
    // setIsLoading(false);
  };

  const handleSocialLogin = (login) => {
    signInWithSocialLogin(login);
  };

const onTokenSignIn = async ({
  customToken,
  layout,
  companyId,
  uid
}) => {
    setIsLoading(true);
    if (customToken && layout === 'upmarket' ) {
      await signInWithCustomToken(
        {
          token: customToken, 
          layout: APP_LAYOUT.UPMARKET, 
          copmanyID: companyId, 
          uID: uid
        }
      );
    }else{
      setPageLoader(false)
    }
  }

  useEffect(() => {
    if (apiKey) {
      const isEmailLogin = getIsSignInWithEmail();
      if (isEmailLogin) {
        setIsLoading(false)
        setShowEmailConfirmation(true);
      }
    }
  }, [apiKey]);

  useEffect(() => {
    const url = document.location.search.replaceAll("?","").split("&")
    const params = {};
    url.map(p => {
      let vars = p.split("=")
      params[vars[0]] = vars[1]
    })

    if (currentUser) {
      const appUserId = localStorage.getItem("appUserId");
      if (appUserId && uid && companyId) {
        updateZoomAuth(appUserId);
      } else if (!appUserId) {
        onTokenSignIn(params)
        if(uid && companyId){
          navigate("/home");
          setIsLoading(false)
        }
      }
    }else if(!currentUser && params.customToken){
      onTokenSignIn(params)
    }
  }, [currentUser, uid, companyId]);


  return (
    <Container>
      {isLoading  && <Loader />}
      {!isLoading ? showEmailConfirmation ? (
        <form onSubmit={handleConfirmation} className="form-signin">
          <p>Please enter the email for confirmation</p>
          <InputForm
            type="email"
            value={values.confirmationEmail}
            name="confirmationEmail"
            required
            onChange={handleChange}
            placeholder={"Email"}
          />
          <AuthButton color="primary" type="submit">
            Submit
          </AuthButton>
        </form>
      ) : (
        <FormWrapper onSubmit={handleSubmit}
        >
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <img
              style={{
                width: "200px",
                margin: `0 auto ${SPACING.MD}px`,
                display: "block",
              }}
              src={logo}
              alt="Upmarket Logo"
            />
            <h3>Log in to your account</h3>
            <InputForm
              style={{ height: '50px', width: '355px', marginTop: 5 }}
              type="email"
              value={values.email}
              name="email"
              required
              onChange={handleChange}
              placeholder={"Email"}
              floatingLabel={false}
            />
            <InputForm
              style={{ height: '50px', width: '355px' }}
              type="password"
              name="password"
              value={values.password}
              required
              onChange={handleChange}
              placeholder="Password"
              floatingLabel={false}
            />
            <div style={{ alignSelf: 'flex-start' }}>
              <Link href="/forgot-password" className="h6" size={"sm"}>
                Forgot password?
              </Link>
            </div>
            <div className="mt-3">
              <AuthButton color="primary" type="submit"
                style={{ height: '50px', width: '355px' }}
              >
                Log in
              </AuthButton>
            </div>
            <div className="mt-5">
              <Link href="/register/zoom">Not a member? Sign up</Link>
            </div>
          </div>
          <Seperator />
          <SignUpWrapper>
            <h4>Or Sign in with</h4>
            <Button
              type="button"
              onClick={() => handleSocialLogin(SOCIAL_AUTH.MICROSOFT)}
              style={{
                height: '50px', width: '355px', backgroundColor: COLORS.brilliantAzure
                , marginTop: 10
              }}
            >Login with Microsoft</Button>
            <Button
              type="button"
              style={{ height: '50px', width: '355px', backgroundColor: COLORS.white, color: 'black', marginTop: '20px' }}
              onClick={() => handleSocialLogin(SOCIAL_AUTH.GOOGLE)}
            >Login with Google</Button>
            <Button
              type="button"
              onClick={() => handleSocialLogin(SOCIAL_AUTH.LINKEDIN)}
              style={{ height: '50px', width: '355px', backgroundColor: COLORS.greenBlue, marginTop: '20px' }}
            >Login with LinkedIn</Button>
          </SignUpWrapper>
        </FormWrapper>
      ) : null}
      {!isLoading && <Cubicles
        src={cubicles}
        alt="Upmarket cubicle"
      />}
    </Container>
  );
};

export default Login;
