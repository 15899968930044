import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { toast } from "react-toastify";

import { auth, functions } from "../firebase/firebase";
import { getUserInfo, getCustomTokenClaim, removeUserInfoListener, getUsers } from "../redux/actions/users";
import { getUserContent, getUserRoles, deleteContentListeners, getCompanyActivities } from "../redux/actions/content";
import { APP_LAYOUT, APP_THEME, SOCIAL_AUTH, TRUSTED_LINKED_REDIRECT } from "../shared/constants";
import {
  deleteMeetingListeners,
  getUserMeetings,
  getUserScheduledBots,
  getUserScheduledMeetings,
  listenToLiveUserMeetings,
  unsubscribeLiveUserMeetingListener
} from "../redux/actions/meetings";
import { getSalesforceRecordData, getUserSalesforceData, isSalesforceConnected } from "../redux/actions/member";
import { deleteCompanyListeners, getSubscriptionInfo } from "../redux/actions/companies";
import { useRef } from "react";

const GoogleProvider = new firebase.auth.GoogleAuthProvider();

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const isLogout = useRef(false)
  const [socialProfile, setSocialProfile] = useState(null);
  const layout = useSelector(state => state?.content?.layout || APP_LAYOUT.UPMARKET );

  const theme = APP_THEME?.[layout] || APP_THEME[APP_LAYOUT.UPMARKET ]
  const dispatch = useDispatch();
  async function login(email, password) {
    return new Promise(async(resolve, reject) => {
    const findUserCompany = functions.httpsCallable("findUserCompany");
      try {
        const result = await findUserCompany({ email });
        if (result?.data?.companyId) {
          localStorage.setItem("companyId", result.data.companyId);
          dispatch({
            type: "LAYOUT_CONTENT",
            data: {
              layout: APP_LAYOUT.UPMARKET,
              isHeader: true
            }
          });
          return await auth.signInWithEmailAndPassword(email, password);
          // return resolve({companyId: result.data.companyId, uid: authData?.user?.uid})
        }
      } catch (error) {
        console.error(error);
        reject(error)
      }
    return null;
    })
  }
  function setPageLoader(isLoading) {
    dispatch({
      type: "UPDATE_LOADER",
      label: 'pageLoader',
      data: isLoading
    })
  }

  function logout() {
    deleteMeetingListeners();
    deleteContentListeners();
    removeUserInfoListener();
    deleteCompanyListeners();
    unsubscribeLiveUserMeetingListener();
    dispatch({
      type: "USER_PUBLIC_DATA",
      data: {}
    });
    dispatch({
      type: "LAYOUT_CONTENT",
      data: {
        layout: APP_LAYOUT.UPMARKET,
        isHeader: true
      }
    });
    return auth.signOut();
  }

  async function getCustomToken(uid) {
    const { data } = await getCustomTokenClaim(uid);
    return data?.customToken || null;
  }

  async function getCustomClaims(uid, companyId, inviteRef) {
    if (uid && companyId) {
      const res = await functions.httpsCallable("auth-app-custom-claims")({
        uid,
        companyId,
        inviteRef,
      });
      if (res?.data?.token) {
        await auth.signInWithCustomToken(res.data.token);
      }
    }
  }

  async function signInWithCustomToken ({token, layout, companyID, uID})  {
    if(!token) return
    try {
      setPageLoader(true);

      if(  !auth || auth?.currentUser?.uid !== uID ){
        if(auth ){
          isLogout.current = true
         await logout()
        }
        isLogout.current = false
        await auth.signInWithCustomToken(token)
      }else{
        isLogout.current = false
        setPageLoader(false)
      }

      dispatch({
        type: "LAYOUT_CONTENT",
        data: {
          layout: APP_LAYOUT.UPMARKET,
          isHeader: false
        }
      });
    }catch(e){
      setPageLoader(false)
    }
  }
 
  function dispatchLoginEvents(uid, companyId, claims) {
    dispatch({ type: "UPDATE_USER_CLAIMS", data: claims });
    dispatch(getUserInfo(uid, companyId));
    dispatch(getUserContent(uid, companyId));
    dispatch(getCompanyActivities(companyId));
    // dispatch(setContentListener(companyId));
    dispatch(getUserMeetings(uid, companyId));
    dispatch(getUserScheduledMeetings(uid, companyId));
    dispatch(getUserScheduledBots(uid));
    dispatch(getUsers(uid, companyId));
    dispatch(getSubscriptionInfo(companyId));
    dispatch(listenToLiveUserMeetings(companyId,uid ))

    if(claims?.connectedServices?.includes("salesforce") && isSalesforceConnected(uid, companyId)) {
      dispatch(getUserSalesforceData(uid, companyId));
      dispatch(getSalesforceRecordData(uid, companyId));
    }
    // dispatch(getUserRoles(uid, companyId));
  }

  function getIsSignInWithEmail() {
    console.log("getIsSignInWithEmail")
    return auth.isSignInWithEmailLink(window.location.href);
  }

  async function updateInviteUserPassword(password) {
    const user = auth.currentUser;
    const companyId = localStorage.getItem("companyId");
    try {
      if (companyId) {
        await user.updatePassword(password);
        await getCustomClaims(user.uid, companyId, null);
        localStorage.removeItem("inviteRef");
      }
    } catch (error) {
      console.error(error);
      return  error ;
    }
  }

  async function signInWithInviteLink(email, companyId, ref) {
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("inviteRef", ref);
    await auth.signInWithEmailLink(email, window.location.href);
  }

  async function signInWithSocialLogin(login) {
    let response;
    switch (login) {
      case SOCIAL_AUTH.GOOGLE:
        response = firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then(async (result) => {
            const user = result.user;
            const email = result.user?.email;
            try {
              const findUserCompany =
                functions.httpsCallable("findUserCompany");
              const response = await findUserCompany({ email });
            } catch (error) {
              await logout();
              setSocialProfile(user);
            }
          })
          .catch((error) => {
            console.log("CAME TO THE ERRORs");
          });
        break;
      case SOCIAL_AUTH.MICROSOFT:
        const microsoftProvider = new firebase.auth.OAuthProvider(
          "microsoft.com"
        );
        response = firebase
          .auth()
          .signInWithPopup(microsoftProvider)
          .then(async (result) => {
            const user = result.user;
            const email = result.user?.email;
            try {
              const findUserCompany =
                functions.httpsCallable("findUserCompany");
              const response = await findUserCompany({ email });
            } catch (error) {
              await logout();
              setSocialProfile(user);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case SOCIAL_AUTH.LINKEDIN:
        window.open(
          `${TRUSTED_LINKED_REDIRECT}/redirect`,
          "firebaseAuth",
          "height=700,width=400"
        );
        async function handleMessageResponse(event) {
          if (event.origin === TRUSTED_LINKED_REDIRECT) {
            if (event.data) {
              const response = JSON.parse(event.data);
              if (response.customToken) {
                let customTokenUser = await auth.signInWithCustomToken(
                  response.customToken
                );
                customTokenUser = customTokenUser.user.toJSON();
                try {
                  const findUserCompany =
                    functions.httpsCallable("findUserCompany");
                  const response = await findUserCompany({
                    email: customTokenUser.email,
                  });
                } catch (error) {
                  await logout();
                  setSocialProfile(customTokenUser);
                }
              } else if (response.error) {
                toast(response.error, { hideProgressBar: true });
              }
              window.removeEventListener(
                "message",
                handleMessageResponse,
                true
              );
            }
          }
        }
        window.addEventListener("message", handleMessageResponse, true);
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    let isMount = true;
    const idTokenSubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        if(!isMount) return
        const idToken = await user.getIdTokenResult();
        if (idToken.claims?.companyId && !isLogout.current ) {
          dispatchLoginEvents(
            idToken.claims.user_id,
            idToken.claims?.companyId,
            idToken.claims
          );
          setPageLoader(false)
        }
      }
    });
   
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      let userObj = { ...user };
      let idToken = null;
      let companyId = null;
      if (user) {

        if(!isMount) return
        idToken = await user.getIdTokenResult();
        companyId = localStorage.getItem("companyId");
        const inviteRef = localStorage.getItem("inviteRef");
        let socialLogin = false;
  
        let signInProvider = idToken?.claims?.signInProvider
          ? idToken.claims.signInProvider
          : idToken?.signInProvider;

        switch (signInProvider) {
          case "google.com":
          case "microsoft.com":
          case "linkedin":
            socialLogin = true;
            break;

          default:
            break;
        }

        companyId = idToken?.claims?.companyId || companyId;
        if (!companyId ) {
          const email = idToken?.claims?.email;
          const findUserCompany = functions.httpsCallable("findUserCompany");
          try {
            const response = await findUserCompany({ email });
            companyId = response?.data?.companyId;
          } catch (e) {}
        }

        await getCustomClaims(user.uid, companyId, inviteRef);
      } else {
        localStorage.setItem("companyId", "");
        localStorage.setItem("inviteRef", "");
        deleteMeetingListeners();
        removeUserInfoListener()
      }
      if (userObj && Object.keys(userObj)?.length === 0) {
        userObj = null;
      }
      if (companyId || userObj ) {
        setCurrentUser(userObj);
      }else{
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => {
      isMount = false
      unsubscribe();
      idTokenSubscribe();
      deleteMeetingListeners();
      removeUserInfoListener()
    };
  }, []);



  const value = {
    theme,
    currentUser,
    socialProfile,
    setPageLoader,
    setSocialProfile,
    login,
    logout,
    signInWithCustomToken,
    signInWithInviteLink,
    getIsSignInWithEmail,
    updateInviteUserPassword,
    getCustomToken,
    signInWithSocialLogin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
