import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

import AddMeetingBotModal from "./modals/AddMeetingBotModal";
import styled from "styled-components";
import { Alert } from "reactstrap";

import {
  SPACING,
  HOUR_IN_SECONDS,
  TRIAL_TIME_LIMIT,
  ERROR_CODES,
} from "shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { TinyText } from "shared/ui/Headers";
import { Icon } from "shared/ui";
import { ToolTipItem } from "../../shared/ui/ToolTips";
import { addMeetingBot, removeMeetingBot } from "../../redux/actions/meetings";
import { handleUserAutoSchedule } from "../../redux/actions/users";

const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 11px;
`;

const CheckBoxLabel = styled.label`
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 1px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;


function isItBeforeToday(MomentDate) {
  return MomentDate.diff(moment(0, "HH")) < 0;
}
const MeetingLabel = styled.p`
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
`
const MeetingItem = styled.div`
  background-color: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.border.color};
  color: ${({ theme }) => theme?.text?.color};
  ${({ theme, isLast }) => isLast && `margin-bottom: ${SPACING.MD}px`};
  padding: ${SPACING.SM}px 0;
`;

const ScheduleList = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;
`;

const MeetingListItem = ({
  title,
  when,
  id,
  participants,
  scheduledBots,
  addBot,
  isLast,
  theme,
  handleCancelBot
}) => {
  let scheduledTime = null;
  let isBotScheduled =
    scheduledBots &&
    Object.keys(scheduledBots).find(
      (key) => scheduledBots[key]?.nylasEventId === id
    );
  const [isHover, setHover] = useState();

  if (when?.object === "date") {
    scheduledTime = when.date;
  } else if (when?.object === "timespan") {
    scheduledTime = moment.unix(when.start_time).format("h:mm A");
  } else if (when?.object === "datespan") {
    scheduledTime = when.start_date;
  }

  return (
    <MeetingItem isLast={isLast}>
      <div style={{ marginBottom: SPACING.XS }}>
      <ToolTipItem itemId={"meeting_title_"+id} label={title}>
        <MeetingLabel  style={{ marginBottom: 0 }}>{title}</MeetingLabel>
      </ToolTipItem>

        <TinyText>{scheduledTime}</TinyText>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" , alignItems: 'center'}}>
        <div>
          {isBotScheduled && (
            <TinyText
              link
              color={isHover ? theme.colors.danger : theme.text.color}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={() => handleCancelBot(isHover, id)}
            >
              {isHover ? (
                <>
                  {" "}
                  <Icon
                    type="delete"
                    style={{
                      marginRight: SPACING.XXS,
                      color: theme.colors.danger,
                      fontSize: 16,
                    }}
                  />{" "}
                  Cancel notetaker
                </>
              ) : (
                <>
                  {" "}
                  <Icon
                    type="check"
                    style={{
                      marginRight: SPACING.XXS,
                      color: theme.colors.success,
                      fontSize: 16,
                    }}
                  />{" "}
                  Scheduled
                </>
              )}
            </TinyText>
          )}
          {!isBotScheduled && (
            <TinyText link color={theme.text.color} onClick={() => addBot(id)}>
              <Icon
                type="plusCircle"
                style={{
                  marginRight: SPACING.XXS,
                  color: theme.colors.primary,
                  fontSize: 16,
                }}
              />{" "}
              Send notetaker
            </TinyText>
          )}
        </div>
        {participants?.length && (
          <div >
            {[...(participants.length > 4 ? participants.slice(0,4) : participants)].map((val) => (
              <Avatar
                round
                size="25px"
                style={{ marginLeft: -4 }}
                textSizeRatio={2}
                name={val.name}
              />
            ))}
            {participants.length > 4 &&  
              <Avatar
                round
                size="25px"
                style={{ marginLeft: -8 }}
                textSizeRatio={2}
                name={"+"}
              />}
          </div>
        )}
      </div>
    </MeetingItem>
  );
};

const ScheduledMeetings = () => {
  const { scheduledMeetings, scheduledBots } = useSelector(
    (state) => state.meetings
  );

  const { info, isUpdatingAutoJoin, subscription } = useSelector((state) => state.users);
  const [groupedScheduledMeetings, setGroupedScheduledMeetings] = useState({});
  const [showJoinBot, setShowJoinBot] = useState(false);
  const [automaticScheduling, setAutomaticScheduling] = useState(false);
  const dispatch = useDispatch();
  const { theme } = useAuth();

  const { uid, companyId, CalendarEvents } = info || {};

  const handleJoinMeeting = async(eventId) => {
    try {
      await addMeetingBot(uid, companyId, eventId);
      toast("Notetaker added to meeting", {
        type: "success",
        hideProgressBar: true,
      });
    } catch (error) {
      toast(ERROR_CODES.TRIAL_RECORD_LIMIT.message, {
        type: "error",
        hideProgressBar: true,
      });
    }
  };

  const handleCancelBot = async(isRemove, eventId) => {
    if(isRemove) {
      try {
        await removeMeetingBot(uid, companyId, eventId);
        toast("Notetaker removed from meeting", {
          type: "info",
          hideProgressBar: true,
        });
      } catch (error) {
        toast("Error removing Notetaker from meeting", {
          type: "error",
          hideProgressBar: true,
        });
      }
    }
  }

  const handleAutoJoin = (e) => {
    setAutomaticScheduling(e.target.checked);
    toast("Updating auto join setting", {
      type: "info",
      hideProgressBar: true,
    });
    dispatch(handleUserAutoSchedule(uid, companyId, e.target.checked));
  }

  const onJoinMeeting = () => {
    setShowJoinBot(true);
  };

  useEffect(() => {
    setAutomaticScheduling(CalendarEvents?.automaticScheduling || false);
  }, [CalendarEvents])
  

  useEffect(() => {
    if (scheduledMeetings) {
      let groupedMeetings = {};
      Object.keys(scheduledMeetings)
        .filter((key) => scheduledMeetings[key]?.location)
        .map((key) => {
          let meeting = scheduledMeetings[key];
          const { when } = meeting;
          let scheduledTime = null;
          if (when?.object === "date") {
            scheduledTime = moment(when.date);
          } else if (when?.object === "timespan") {
            scheduledTime = moment.unix(when.start_time);
          } else if (when?.object === "datespan") {
            scheduledTime = moment(when.start_date);
          }
        
          // if(isItBeforeToday(scheduledTime)) {
          //     return;
          // }
          const formattedDate = scheduledTime.format("MMMM Do YYYY");
          const formattedTime = scheduledTime.format('YYYY-MM-DD').replaceAll("-","_")
          if (
            groupedMeetings[formattedTime] &&
            groupedMeetings[formattedTime]?.events?.length
          ) {
            groupedMeetings[formattedTime].events.push(meeting);
          } else {
            groupedMeetings[formattedTime] = {label: formattedDate,events: [meeting]};
          }
        });
      Object.keys(groupedMeetings).map(meetingDate => {
        let sorted =  groupedMeetings[meetingDate]?.events.sort((a,b) => a.start_time - b.start_time ) ;
        groupedMeetings[meetingDate].events = sorted || []
      })
      setGroupedScheduledMeetings(groupedMeetings);
    }
  }, [scheduledMeetings]);

  return (
    <Container>
      <AddMeetingBotModal
        isOpen={showJoinBot}
        toggle={() => setShowJoinBot(!showJoinBot)}
      />
      <div style={{ marginBottom: SPACING.LG }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{flexGrow: 1 }}>
            <h3 style={{ fontWeight: "bold"}}>
              Upcoming Meetings
            </h3>
          </span>
          <CheckBoxWrapper>
            <span>Auto Join</span>
            <CheckBox disabled={isUpdatingAutoJoin} id="checkbox" type="checkbox" checked={automaticScheduling} onChange={handleAutoJoin}/>
            <CheckBoxLabel htmlFor="checkbox" />
          </CheckBoxWrapper>
        </div>
        <TinyText link onClick={onJoinMeeting}>
          Join a meeting
        </TinyText>
      </div>
      {(subscription?.status === "free" || subscription?.status === "trial") && info?.meetingSeconds >= TRIAL_TIME_LIMIT && <Alert color="danger"> Meeting Recording Limit Exceeded</Alert>}

      <ScheduleList>
        {groupedScheduledMeetings &&
          Object.keys(groupedScheduledMeetings)
          .sort()
          .map((key, i) => {
            const meetingList = groupedScheduledMeetings[key]?.events || [];
            return (
              <div key={key + "_" +i}>
                <h4 style={{ fontWeight: "700" , margin: 0}}>{groupedScheduledMeetings[key]?.label}</h4>
                <div>
                  {meetingList.map((val, index) => (
                    <MeetingListItem
                      theme={theme}
                      isLast={Object.keys(meetingList).length - 1 === index}
                      addBot={handleJoinMeeting}
                      scheduledBots={scheduledBots}
                      handleCancelBot={handleCancelBot}
                      {...val}
                      key={key +"_"+index+ "_" +i}

                    />
                  ))}
                </div>
              </div>
            );
          })}
      </ScheduleList>
    </Container>
  );
};

export default ScheduledMeetings;
